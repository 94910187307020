<template>
  <div id="overlay" v-if="overlay"></div>
  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Nombre
    </template>
    <template v-slot:subtitle>
      Añadir usuario
    </template>

    <template #content>
      <div class="filters">

        <!--          Name            -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="name" type="text" v-model="name" class="form-input"
                       v-bind:style="{cursor: 'text'}"
            />
            <label for="name">Nombre</label>
          </span>
        </div>
        <!--          Email           -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="email" type="text" v-model="email" class="form-input"  v-bind:style="{cursor: 'text'}"/>
            <label for="email">Correo Electrónico</label>
          </span>
        </div>
        <!--          Password        -->
        <div class="field">
          <span class="p-float-label">
            <InputText id="pass"
                       type="text"
                       v-model="pass"
                       class="form-input"
                       ref="pass"
                       v-bind:style="{cursor: 'text'}"
                       v-on:keyup="hidePass($event)"
            />
            <label for="pass">Contraseña</label>
          </span>
        </div>
      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-end">
        <!--          <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" />-->
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                v-bind:style="{cursor: buttonCursor}"
                :disabled="this.name && this.email && this.password ? disabled : ''"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>


import UserService from "@/service/backend/UserService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Step1",
  mixins: [generalMixin],
  created() {
    // this.distributorName = this.$store.getters.getDistributor.distributorName;
    this.userService = new UserService;
  },
  mounted() {
    this.name = this.$store.getters.getUser.name;
    this.email =this.$store.getters.getUser.email;
    this.password = this.$store.getters.getUser.password;

  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/usuarios/agregar-1'
        },
        {
          label: 'Nivel de Acceso',
          to: '/usuarios/agregar-2'
        },
      ],
      name: null,
      email: null,
      password: null,
      pass: null,
      passArr: [],

      pageIndex: 0,
      roleId: null,
      userService: null,
      buttonCursor: 'pointer',
      overlay: false,
    }
  },

  methods: {
    hidePass(input) {
      if (input.key === 'Backspace') {
        this.passArr.pop();
      } else {
        this.passArr.push(input.key);
      }

      this.password = this.passArr.toString();
      this.pass = "*".repeat(this.passArr.length);
    },
    async nextPage() {
      this.buttonCursor = 'progress'
      this.overlay = true;
      const existingUser = await this.checkEmailDoesntExist();
      if (!existingUser) {
        this.$store.commit('setUser', {
          name: this.name,
          email: this.email,
          password: this.password.replaceAll(',',''),
          id_comercializador: this.$store.getters.getUser.id_comercializador,
          id_banca: this.$store.getters.getUser.id_banca,
          id_distribuidor: this.$store.getters.getUser.id_distribuidor,
        });
        this.$router.push({name: 'usuarios/agregar-2',})
      } else {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Este correo ya existe. Por favor seleccione otro.',
          life: 10000
        });
        this.overlay = false;
        this.$router.push({name: 'usuarios/agregar-1',})
      }

    },
    async checkEmailDoesntExist() {
      return await this.userService.exists({email: this.email})
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

::v-deep(.password  .p-inputtext) {
  width: 100% !important;
}

.field {
  margin: 40px;
}

.form-input {
  width: 100%;
}
</style>
import BaseService from "./BaseService";

class UserService extends BaseService {

  async getAll(req) {
    const response = await this.axios.post('/user/all', req);
    return await response.data;
  }

  async getAllSecurity() {
    const response = await this.axios.get('/user/security');
    return await response.data;
  }

  async delete(req) {
    const response = await this.axios.post('/user/delete', req);
    return await response.data;
  }

  async create(req) {
    const response = await this.axios.post('/user/create', req);
    return await response.data;
  }

  async update(req) {
    const response = await this.axios.post('/user/update', req);
    return await response.data;
  }

  async block(req) {
    const response = await this.axios.post('/user/block', req);
    return await response.data;
  }

  async getProfile(req) {
    const response = await this.axios.post('/user/profile', req);
    return await response.data;
  }

  async exists(req) {
    const response = await this.axios.post('/user/exists', req);
    return await response.data;
  }

  async getEntityIds(req) {
    const response = await this.axios.post('/user/entities', req);
    return await response.data;
  }

  async getConalot(req) {
    const response = await this.axios.post('/user/conalot', req);
    return await response.data;
  }

  async createConalot(req) {
    const response = await this.axios.post('/user/conalot/create', req);
    return await response.data;
  }

  async updateConalot(req) {
    const response = await this.axios.post('/user/conalot/update', req);
    return await response.data;
  }
  async deleteConalot(req) {
    const response = await this.axios.post('/user/conalot/delete', req);
    return await response.data;
  }

}

export default UserService;
